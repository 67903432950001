import * as Sentry from '@sentry/nextjs';
import toast from 'react-hot-toast';
import { LESSON_API, PostRequestPayload } from 'constants/payloads/lessons';
import { useApiGateway } from 'hooks/useApi';
import ReceiptRefund from 'svg/ReceiptRefund';
import WarnExclamation from 'svg/WarnExclamation';
import Modal from 'components/modals/Modal';
import ModalTitle from 'components/modals/ModalTitle';

export interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  lessonId: string;
  handleCancelComplete: () => void;
  handleBack?: () => void;
}

const ModalCoachCancelPrompt: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  lessonId,
  handleBack,
  handleCancelComplete,
}) => {
  const { post: cancelLesson, isLoading: isCancellingLesson } = useApiGateway<PostRequestPayload>(
    `${LESSON_API}/${lessonId}/coach/cancel`,
  );

  return (
    <>
      <Modal isOpen={isOpen} handleClose={() => !isCancellingLesson && setIsOpen(false)}>
        <div className="p-6">
          <ModalTitle>Cancellation policy</ModalTitle>
          <div className="mt-6 space-y-6">
            <div className="flex">
              <div className="h-5 w-5 text-color-success">
                <ReceiptRefund className="h-full" />
              </div>
              <div className="ml-3 text-sm leading-5 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                When you cancel a lesson, all players who have booked that lesson through Bounce
                will receive a full refund immediately. For players that paid you directly, it is up
                to you to refund them outside of Avantage.
              </div>
            </div>
            <div className="flex">
              <div className="h-5 w-5 text-color-error">
                <WarnExclamation className="h-full" />
              </div>
              <div className="ml-3 text-sm leading-5 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
                We encourage you not to cancel lessons as this could damage your reputation as a top
                coach.
              </div>
            </div>
            <div className="text-base font-semibold leading-6 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
              Cancel now and refund all participants.
            </div>
            <div className="flex w-full flex-col space-y-4">
              <button
                onClick={() => {
                  setIsOpen(false);
                  handleBack?.();
                }}
                type="button"
                className="button-rounded-full-primary-inverted"
                disabled={isCancellingLesson}
              >
                Back
              </button>
              <button
                onClick={async () => {
                  try {
                    await cancelLesson({
                      payload: {
                        lessonId,
                      },
                    });
                    handleCancelComplete();
                  } catch (error) {
                    // @ts-ignore
                    toast.error(error.message);
                    Sentry.captureException(error);
                  }
                }}
                type="button"
                className="button-rounded-full-primary"
                disabled={isCancellingLesson}
              >
                Cancel now
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalCoachCancelPrompt;
