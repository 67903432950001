import * as React from 'react';

export default function HeartRate({
  className,
  viewBox = '0 0 16 16',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.10626 9.20016C4.02654 10.6721 5.58436 12.2969 8.0002 14.0001C10.416 12.2969 11.9739 10.6721 12.8941 9.20016H11.0003C10.9254 9.20014 10.8523 9.1762 10.7902 9.13143C10.7282 9.08666 10.6802 9.02311 10.6523 8.94896L9.55951 6.03542L8.36097 10.5097C8.33954 10.59 8.29512 10.6611 8.23393 10.713C8.17274 10.765 8.09784 10.7953 8.01972 10.7996C7.9416 10.8039 7.86416 10.7821 7.79826 10.7371C7.73236 10.6922 7.68128 10.6264 7.65219 10.5489L6.41689 7.2546L5.3121 9.02256C5.2778 9.07725 5.23138 9.12207 5.17695 9.15305C5.12252 9.18403 5.06177 9.20021 5.00008 9.20016H3.10626ZM2.65924 8.40017C0.180392 3.34428 5.3076 0.400336 7.8682 2.91469C7.9132 2.95868 7.95745 3.00428 8.0002 3.05148C8.04252 3.00433 8.08656 2.95896 8.13221 2.91549C10.6928 0.400336 15.82 3.34428 13.3404 8.40017H11.2531L9.84753 4.65145C9.81843 4.574 9.76736 4.50819 9.70145 4.46324C9.63555 4.41829 9.55812 4.39645 9.48 4.40077C9.40188 4.40509 9.32698 4.43536 9.26579 4.48734C9.2046 4.53932 9.16018 4.61041 9.13875 4.69065L7.94095 9.16496L6.84816 6.25142C6.82271 6.18383 6.78044 6.12494 6.72591 6.08109C6.67138 6.03725 6.60666 6.01012 6.53874 6.00262C6.47081 5.99513 6.40226 6.00757 6.34047 6.03858C6.27868 6.0696 6.22601 6.11802 6.18813 6.17862L4.79908 8.40017H2.65999H2.65924Z"
        fill="currentColor"
      />
    </svg>
  );
}
