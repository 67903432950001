function getFirstNameLastInitial(name: string) {
  const nameParts = name.split(' ');
  if (nameParts.length < 2) {
    return name; // return the name as is if there is no last name
  }
  const firstName = nameParts[0];
  const lastNameInitial = nameParts[1].charAt(0).toUpperCase();
  return `${firstName} ${lastNameInitial}`;
}
export default getFirstNameLastInitial;
