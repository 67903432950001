export default function GoogleCalendarLogo({
  className,
  viewBox = '0 0 28 28',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" className={className} viewBox={viewBox}>
      <g clip-path="url(#clip0_6707_22397)">
        <path d="M20.3161 7.6842H7.68457V20.3157H20.3161V7.6842Z" fill="white" />
        <path
          d="M20.3156 26.0001L25.9998 20.3158L23.1577 19.8309L20.3156 20.3158L19.7969 22.9155L20.3156 26.0001Z"
          fill="#EA4335"
        />
        <path
          d="M2 20.3158V24.1053C2 25.1522 2.84787 26.0001 3.89469 26.0001H7.68425L8.26784 23.1579L7.68425 20.3158L4.58741 19.8309L2 20.3158Z"
          fill="#188038"
        />
        <path
          d="M25.9998 7.68425V3.89469C25.9998 2.84787 25.152 2 24.1052 2H20.3156C19.9698 3.40962 19.7969 4.447 19.7969 5.11212C19.7969 5.77719 19.9698 6.63456 20.3156 7.68425C21.5728 8.04425 22.5202 8.22425 23.1577 8.22425C23.7953 8.22425 24.7427 8.04431 25.9998 7.68425Z"
          fill="#1967D2"
        />
        <path d="M25.9997 7.6842H20.3154V20.3157H25.9997V7.6842Z" fill="#FBBC04" />
        <path d="M20.3161 20.3158H7.68457V26H20.3161V20.3158Z" fill="#34A853" />
        <path
          d="M20.3158 2H3.89478C2.84788 2 2 2.84787 2 3.89469V20.3158H7.68425V7.68425H20.3158V2Z"
          fill="#4285F4"
        />
        <path
          d="M10.2753 17.4832C9.80316 17.1642 9.47626 16.6985 9.29785 16.0825L10.3937 15.631C10.4931 16.01 10.6668 16.3037 10.9148 16.5121C11.161 16.7205 11.461 16.8232 11.8116 16.8232C12.1699 16.8232 12.4778 16.7142 12.7352 16.4962C12.9926 16.2784 13.1221 16.0005 13.1221 15.6642C13.1221 15.32 12.9863 15.0389 12.7148 14.821C12.4432 14.6032 12.1021 14.4942 11.6948 14.4942H11.0616V13.4095H11.63C11.9805 13.4095 12.2757 13.3147 12.5157 13.1252C12.7557 12.9358 12.8757 12.6768 12.8757 12.3468C12.8757 12.0532 12.7684 11.8194 12.5537 11.6442C12.339 11.4689 12.0673 11.3805 11.7373 11.3805C11.4152 11.3805 11.1594 11.4658 10.97 11.6378C10.7806 11.8104 10.6382 12.0283 10.5563 12.271L9.47157 11.8194C9.6152 11.4121 9.87891 11.0521 10.2657 10.741C10.6525 10.43 11.1468 10.2737 11.7468 10.2737C12.1905 10.2737 12.59 10.359 12.9437 10.531C13.2973 10.7032 13.5752 10.9416 13.7757 11.2447C13.9764 11.5494 14.0757 11.8905 14.0757 12.2694C14.0757 12.6562 13.9826 12.9832 13.7964 13.2516C13.6101 13.52 13.381 13.7252 13.1094 13.8689V13.9336C13.4601 14.0782 13.7647 14.3157 13.9905 14.6205C14.2194 14.9284 14.3348 15.2962 14.3348 15.7258C14.3348 16.1552 14.2257 16.5389 14.0079 16.8752C13.79 17.2116 13.4885 17.4768 13.1063 17.6694C12.7226 17.8621 12.2916 17.9601 11.8132 17.9601C11.2589 17.9616 10.7474 17.8021 10.2753 17.4832ZM17.0062 12.0452L15.8031 12.9152L15.2015 12.0026L17.3599 10.4457H18.1872V17.7894H17.0062V12.0452Z"
          fill="#4285F4"
        />
      </g>
      <defs>
        <clipPath id="clip0_6707_22397">
          <rect width="24" height="24" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}
