import * as React from 'react';

export default function Racket({
  className,
  viewBox = '0 0 12 14',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.30848 9.97708C6.38519 10.8428 9.11406 9.68773 10.5787 7.2508C12.1297 4.67075 11.6764 1.63023 9.56508 0.459602C7.45537 -0.711026 4.48684 0.4316 2.93579 3.01243C1.65032 5.15067 1.74147 7.60549 3.0128 9.04991L0.5 13.23L1.88997 14L4.30848 9.97708ZM9.20835 6.49087C8.9266 6.96746 8.56954 7.39629 8.15075 7.76106V1.58667C8.37704 1.62634 8.5829 1.69712 8.76205 1.79669C9.84244 2.39561 10.4946 4.35107 9.20835 6.49087ZM5.74403 8.71156V2.20893C6.27676 1.84335 6.83306 1.63956 7.34851 1.57345V8.30709C6.79849 8.59489 6.24061 8.72089 5.74403 8.71156ZM4.94179 8.55599C4.87714 8.52971 4.81417 8.49958 4.75321 8.46577C3.67203 7.86684 3.02065 5.91139 4.30691 3.77159C4.50177 3.44645 4.71549 3.16099 4.94179 2.91287V8.55599Z"
        fill="currentColor"
      />
    </svg>
  );
}
