import * as React from 'react';

export default function Shoes({ className, viewBox = '0 0 13 14' }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="13" height="14" viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20235 7.48647C5.56446 8.43412 5.52594 9.42028 4.43962 9.81321C2.27036 10.6136 1.73516 8.59612 1.67765 8.3793L1.67373 8.36478L5.20235 7.48647ZM1.32703 6.86241L4.65534 6.03804C4.56623 5.54558 4.61416 5.05312 4.66531 4.52763C4.69818 4.18991 4.73239 3.83854 4.73239 3.46476C4.73239 2.17042 3.7077 -0.364332 2.07436 0.0440018C0.209889 0.506267 -0.0597654 2.57876 0.00957439 3.58033C0.0866696 4.41553 0.913798 6.04362 1.21774 6.64189C1.27829 6.76107 1.31807 6.83938 1.32703 6.86241Z"
        fill="currentColor"
      />
      <path
        d="M10.3827 12.2747C10.3596 12.3902 9.85152 14.5457 7.61903 13.7297C6.54127 13.3294 6.49509 12.344 6.8569 11.3971L10.3827 12.2747ZM12.0378 7.50179C12.1148 6.50101 11.8531 4.42249 9.98239 3.96059C8.35806 3.53719 7.3342 6.08531 7.3342 7.38631C7.3342 8.3332 7.54975 9.14152 7.41888 9.94983L10.7291 10.7735C10.7907 10.635 11.9531 8.49486 12.0378 7.50179Z"
        fill="currentColor"
      />
    </svg>
  );
}
