const AppleLogo = ({ className, viewBox = '0 0 28 28' }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} fill="none" className={className}>
      <g fill="#000" clipPath="url(#a)">
        <path d="M19.568.014c-.06-.066-2.203.026-4.068 2.051-1.866 2.023-1.579 4.344-1.537 4.403.042.06 2.66.152 4.331-2.201C19.966 1.913 19.628.082 19.568.014Zm5.8 20.533c-.084-.168-4.069-2.16-3.698-5.989.371-3.829 2.931-4.88 2.972-4.994.04-.114-1.045-1.383-2.195-2.025a6.475 6.475 0 0 0-2.735-.76c-.19-.005-.845-.166-2.195.204-.889.243-2.892 1.03-3.444 1.062-.553.031-2.198-.914-3.967-1.164-1.132-.219-2.333.23-3.192.574-.857.343-2.488 1.32-3.63 3.915-1.14 2.593-.544 6.702-.117 7.98.427 1.277 1.094 3.367 2.228 4.893 1.008 1.722 2.345 2.917 2.903 3.323.559.406 2.134.675 3.226.117.878-.539 2.464-.849 3.09-.826.625.023 1.857.27 3.119.944.999.344 1.944.2 2.89-.184.947-.387 2.318-1.854 3.917-4.827.607-1.382.883-2.13.828-2.243Z" />
        <path d="M19.568.014c-.06-.066-2.203.026-4.068 2.051-1.866 2.023-1.579 4.344-1.537 4.403.042.06 2.66.152 4.331-2.201C19.966 1.913 19.628.082 19.568.014Zm5.8 20.533c-.084-.168-4.069-2.16-3.698-5.989.371-3.829 2.931-4.88 2.972-4.994.04-.114-1.045-1.383-2.195-2.025a6.475 6.475 0 0 0-2.735-.76c-.19-.005-.845-.166-2.195.204-.889.243-2.892 1.03-3.444 1.062-.553.031-2.198-.914-3.967-1.164-1.132-.219-2.333.23-3.192.574-.857.343-2.488 1.32-3.63 3.915-1.14 2.593-.544 6.702-.117 7.98.427 1.277 1.094 3.367 2.228 4.893 1.008 1.722 2.345 2.917 2.903 3.323.559.406 2.134.675 3.226.117.878-.539 2.464-.849 3.09-.826.625.023 1.857.27 3.119.944.999.344 1.944.2 2.89-.184.947-.387 2.318-1.854 3.917-4.827.607-1.382.883-2.13.828-2.243Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppleLogo;
