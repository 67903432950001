export default function OutlookLogo({
  className,
  viewBox = '0 0 28 28',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox={viewBox} fill="none">
      <path
        fill="#0072C6"
        d="M17.049 6.945v4.792l1.676 1.055c.06.013.123.013.184 0l7.208-4.86a1.027 1.027 0 0 0-.839-.987h-8.23Z"
      />
      <path
        fill="#0072C6"
        d="m17.049 13.525 1.528 1.05a.455.455 0 0 0 .475 0c-.262.158 7.064-4.706 7.064-4.706v8.808a1.232 1.232 0 0 1-1.303 1.36h-7.765v-6.512Zm-7.914-2.21a1.409 1.409 0 0 0-1.242.734 3.613 3.613 0 0 0-.46 1.94 3.544 3.544 0 0 0 .46 1.936 1.4 1.4 0 0 0 2.424.02 3.5 3.5 0 0 0 .45-1.926 3.825 3.825 0 0 0-.437-1.996 1.347 1.347 0 0 0-1.195-.707Z"
      />
      <path
        fill="#0072C6"
        d="M1.884 4.51v18.75l14.262 2.99V1.75L1.884 4.51Zm9.544 12.545a2.827 2.827 0 0 1-2.362 1.19 2.79 2.79 0 0 1-2.31-1.153 4.778 4.778 0 0 1-.888-3.004 5.136 5.136 0 0 1 .906-3.164 2.861 2.861 0 0 1 2.401-1.211 2.73 2.73 0 0 1 2.284 1.155c.62.893.927 1.964.875 3.049a5.04 5.04 0 0 1-.906 3.138Z"
      />
    </svg>
  );
}
